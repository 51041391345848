var paychannelList = [
{
    channel: 'OXuxUts2H',
    sceneNo: '0ngvMJmSD',
    instructions: '金融 掌厅打开的是，钱包支付 微信端打开只有微信支付'
}]


function changePayType(sceneNo) {
    var payType = 0;      //0为钱包支付宝微信  1为钱包
    paychannelList.forEach((item,index) =>{
        if(item.sceneNo==sceneNo){
            payType = 1
        }
    })
    return payType;
}

export default changePayType;